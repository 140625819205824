<template>
  <main class="d-flex">
    <div class="details">
      <h3>Log in to your account</h3>
      <form id="form"></form>
    </div>
    <div class="n">

    </div>
  </main>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",
  mounted() {
      this.$nextTick()
    var uiConfig = {
      signInSuccessUrl: "/subscribe",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase,
      ],
    };
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#form", uiConfig);
  },
};
</script>

<style scoped>
main{
    height: 89.2vh;
    justify-content: space-between;
}
.details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    padding: 3em;
}
.details h3{
    margin-left: 20px;
}
.n{
    height: 100%;
    width: 1100px;
    background-color: #8a8ae2;
    opacity: 0.8;
    background-image:  linear-gradient(30deg, #7076e0 12%, transparent 12.5%, transparent 87%, #7076e0 87.5%, #7076e0), linear-gradient(150deg, #7076e0 12%, transparent 12.5%, transparent 87%, #7076e0 87.5%, #7076e0), linear-gradient(30deg, #7076e0 12%, transparent 12.5%, transparent 87%, #7076e0 87.5%, #7076e0), linear-gradient(150deg, #7076e0 12%, transparent 12.5%, transparent 87%, #7076e0 87.5%, #7076e0), linear-gradient(60deg, #7076e077 25%, transparent 25.5%, transparent 75%, #7076e077 75%, #7076e077), linear-gradient(60deg, #7076e077 25%, transparent 25.5%, transparent 75%, #7076e077 75%, #7076e077);
    background-size: 20px 35px;
    background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}


</style>